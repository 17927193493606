import {TranslateService} from "@ngx-translate/core";

export class CommonUtils {

    static MONEY_NUMBER_FORMATTER: Intl.NumberFormat = new Intl.NumberFormat(
        /* RU-язык выбран осознанно, никто еще не ругался на локаль :) */
        "ru",
        {
            "useGrouping": true,
            "minimumFractionDigits": 2,
            "maximumFractionDigits": 2,
        }
    );

    static COUNT_NUMBER_FORMATTER: Intl.NumberFormat = new Intl.NumberFormat(
        /* RU-язык выбран осознанно, никто еще не ругался на локаль :) */
        "ru",
        {
            "useGrouping": true,
            "minimumFractionDigits": 0,
            "maximumFractionDigits": 3,
        }
    );

    static SUPPORTED_LANGUAGES : Array<string> = Array.of('ru', 'en', 'de', 'tr');

    static CULTURE_MAP: Map<string, string> = new Map<string, string>([
        ["en", "en-US"],
        ["ru", "ru-RU"],
        ["tr", "en-US"],
        ["de", "en-US"]
    ]);

    static lang: string;
    static langForHot: string;

    static getLangFromCookie(): string {
        let locale = this.getValueFromCookie('PLAY_LANG');
        if (!locale) {
            locale = this.getLangFromBrowser();
        }
        return locale;
    }

    static getLangFromBrowser(): string {
        const windowLang: string = window.navigator.language;
        let lang = this.SUPPORTED_LANGUAGES.find(language => windowLang.includes(language));
        return lang || "en";
    }

    static getValueFromCookie(key: string): string {
        const cookie = document.cookie;
        const parts = cookie.split(`; ${key}=`);
        if (parts.length === 2) {
            return parts.pop().split(';').shift();
        }
    }

    static setLanguagePreference(currentUser: any, translateService: TranslateService): void {
        if (currentUser && currentUser.uiLanguage) {
            translateService.use(currentUser.uiLanguage.toLowerCase())
        } else if (currentUser && currentUser.company.lang) {
            translateService.use(currentUser.company.lang.toLowerCase())
        } else {
            translateService.use(this.getLangFromCookie());
        }
    }

    static formatMoney(amount: number | bigint | string): string {
        return this.formatNumber(amount, this.MONEY_NUMBER_FORMATTER).replace('.', ',');
    }

    static formatCount(amount: number | bigint | string): string {
        return this.formatNumber(amount, this.COUNT_NUMBER_FORMATTER);
    }

    static formatNumber(amount: number | bigint | string, formatter: Intl.NumberFormat): string {
        const val = Number(amount);
        return (
            val != null
            && !isNaN(val)
            && isFinite(val)
            && formatter.format(val)
            || ''
        );
    }

    static roundMoney(amount): number {
        return this.roundDecimal(amount, 2);
    }

    static roundDecimal(number, precision): number {
        if (typeof number !== "number") {
            return NaN;
        }
        let base = Math.pow(10, precision);
        return (Math.round(number * base) / base);
    }


    static getLang(currentUser: any): string {
        if (currentUser?.uiLanguage) {
            return currentUser.uiLanguage.toLowerCase();
        }
        if (currentUser?.company?.lang) {
            return currentUser.company.lang.toLowerCase();
        }
        return this.getLangFromCookie();
    }

    static setLangForHot(currentUser: any) {
        this.lang = this.getLang(currentUser);
        this.langForHot = this.CULTURE_MAP.get(this.lang);
    }

    static getHotTableLang() {
        return this.langForHot;
    }

    static equals(o: Object, o1: Object) {
        if (o1 == null || o == null) return false;
        const firstObjectProps: string[] = Object.keys(o);
        const secondObjectProps: string[] = Object.keys(o1);
        const hasSameLengthProps = firstObjectProps.length == secondObjectProps.length;
        if (hasSameLengthProps) {
            return firstObjectProps.every(fop => o[fop] == o1[fop]);
        }
        return false;
    }

}
